/* Guido */

p,
h1,
h2,
h3,
h4,
h5,
h6,
html,
body {
  margin: 0;
}

a {
  text-decoration: none;
}
.navegacion {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
  position: fixed;
  background-color: #fff;
  width: 100%;
  z-index: 999;
}

.navegacion img {
  max-height: initial;
}

.nera-btn {
  background-color: #009b41;
  border-radius: 24px;
  padding: 15px 16px;
  display: flex;
  color: #fff;
  text-transform: uppercase;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
  font-family: "Public Sans";
}

.nera-btn:hover {
  background-color: #03dd5e;
  color: #000;
  scale: 1.05;
}

.nera-btn:active {
  scale: 0.95;
  transition: none;
}

.navegacion-flex {
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 0px 20px
}

@media (max-width: 800px) {
  .navegacion-flex {
    gap: 10px;
   
  }
}

.navegacion-flex p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  text-align: center;
  letter-spacing: 0.01em;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  font-family: "Inter";
  color: #505c6f;
}

.navegacion-flex p:hover {
  color: #009b41;
}

.header-banner.row img {
  width: 100%;
}

.wt-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.welcome-flex {
  max-width: 410px;
}

.welcome-flex h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 42px;
  /* or 105% */

  /* Monocromos/Negro 80 */

  color: #505c6f;
  margin-bottom: 16px;
  font-family: "Public Sans";
}

.welcome-flex h4 span {
  color: #009b41;
  font-weight: 700;
}

.welcome-flex p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* or 138% */

  color: #505c6f;
  font-family: "Public Sans";
}

.welcome-flex p span {
  font-weight: 700;
}

.nera-container {
  max-width: 1020px;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 0px 32px;
}

.nera-container-fluid {
  max-width: 1020px;
  box-sizing: content-box;
  margin: 0 auto;
}

.card-container {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 60px 0px;
}

.card-info {
  background: #ffffff;
  border-left: 2px solid #19e572;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 324px;
}

.card-info .titulo {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  /* Monocromos/Negro 80 */

  color: #505c6f;
  margin-bottom: 4px;
}

.card-info .descripcion {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Monocromos/Negro 80 */

  color: #505c6f;
  display: block;
}

.ilustracion-texto {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ilustracion-texto h1 {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 42px;
  /* or 105% */

  /* Monocromos/Negro 80 */

  color: #505c6f;
  max-width: 500px;
}

.ilustracion-texto h1 span {
  color: #08582b;
  font-weight: 700;
}

.ilustracion-texto h1 span::before {
  content: "\a";
  white-space: pre;
}

.ilustracion-img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nera-footer {
  background: #0f1216;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}

.derechos p {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */

  /* Monocromos/Negro 40 */

  color: #ced1d6;
}

.logo-y-redes {
  display: flex;
  gap: 40px;
  align-items: center;
}

.input-flex {
  display: flex;
  gap: 16px;
}

.label-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
  padding-bottom: 16px;
}

.label-input input {
  height: 40px;
  border: 1px solid #ced1d6;
  border-radius: 4px;
  width: 100%;
}

.label-input textarea {
  height: 82px;
  border: 1px solid #ced1d6;
  border-radius: 4px;
  width: 100%;
}

.label-input label {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Monocromos/Negro 80 */

  color: #505c6f;
}

.form-bottom {
  display: flex;
  align-items: center;
  gap: 28px;
  justify-content: space-between;
}

.form-bottom p {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Monocromos/Negro 80 */

  color: #505c6f;
  max-width: 275px;
}

.form-col {
  padding: 28px 0px 11px 28px;
}

.form-bottom p span {
  font-weight: 700;
}

.form-bottom p span::before {
  content: "\a";
  white-space: pre;
}

.label-input label {
  padding-bottom: 4px;
}

.hamburger {
  display: none;
}

.dropdown {
  display: none;
  position: fixed;
  top: 62px;
  background-color: #fff;
  width: 100%;
  left: 0;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  padding: 24px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}

.dropdown p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  text-align: center;
  letter-spacing: 0.01em;

  /* Monocromos/Negro 80 */

  color: #505c6f;
  transition: all 0.3s ease-in-out;
}

.dropdown p:hover,
.dropdown p:active {
  color: #03dd5e;
}
.login-dropdown {
  position: fixed;
  width: 120px;
  background-color: #fff;
  right: 34px;
  top: 80px;
  padding: 16px 16px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Monochrome/white 0 */

  background: #ffffff;
  /* App/cards */

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.login-dropdown p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  letter-spacing: 0.01em;

  /* Monochrome/grey 80 */

  color: #505c6f;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.login-dropdown p:hover {
  color: #03dd5e;
}

.dropdown.active {
  display: flex;
}
@media (max-width: 1024px) {
}

@media (max-width: 1000px) {
  .card-container {
    overflow-x: scroll;
  }
  .header-col-1 {
    order: 2;
  }
  .header-col-2 {
    order: 1;
  }
  .welcome-flex {
    max-width: 100%;
    padding: 32px;
  }
  .welcome-flex h4 {
    font-size: 24px;
    line-height: 28px;
  }

  .welcome-flex p {
    font-size: 16px;
    line-height: 22px;
  }

  .ilustracion-texto {
    order: 2;
    justify-content: center;
  }

  .ilustracion-img {
    order: 1;
    justify-content: center;
  }

  .ilustracion-texto h1 {
    font-size: 32px;
    line-height: 36px;
  }
  .ilustracion-img img {
    max-width: 100%;
  }


  .form-img img {
    width: 100%;
  }

  .form-col img {
    display: none;
  }

  .form-col {
    padding: 30px 32px 40px 32px;
  }

  .form-section .input-flex {
    flex-direction: column;
    gap: 8px;
  }

  .label-input {
    padding-bottom: 28px;
  }
  .form-bottom {
    flex-direction: column;
  }

  .form-bottom p {
    max-width: 100%;
  }
}

@media (max-width: 800px) {
  .nera-footer {
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
  }

  .derechos {
    margin: auto;
  }
  .derechos p {
    text-align: center;
  }

  .logo-y-redes {
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .navegacion-flex {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .login-dropdown {
    top: 370px;
    left: 30px;
  }
}
