/* Joe */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Manrope:wght@400;500;600;700;800&family=Maven+Pro:wght@400;500;600;700;800;900&family=Montserrat:wght@200;300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700;800&family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&family=Rubik:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro&display=swap");
* {
  font-family: "Public Sans", sans-serif;
}


/* HOME */  
/* LAPTOP BANNER */
.laptop-banner {
  background-color: #009b41;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1020px;
  height: 360px;
  margin: 100px auto;
}

.laptop-banner .laptop {
  position: relative;
}

.laptop-banner .laptop .mobile {
  display: none
}

.laptop-banner .info {
  padding: 50px 0px;
  position: relative;
  left: -100px;
}

.laptop-banner .info h3 {
  font-family: "Public Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  margin: 25px 0px 25px 0px;
}

.laptop-banner .flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
}

.laptop-banner .flex-container span {
  background-color: #fff;
  color: #08582b;
  border-radius: 4px;
  font-family: "Public Sans";
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  width: 128px;
  height: 48px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.laptop-banner .info p {
  font-family: "Public Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;
}

@media (max-width: 1000px) {
  .laptop-banner {
    width: 768px;
    height: 300px;
  }

  .laptop-banner .info {
    padding: 20px 0px 20px 20px;
  }

  .laptop-banner .info h3 {
    margin: 15px 0px;
  }

  .laptop-banner .info span {
    position: relative;
    top: 8px;
  }

  .laptop img {
    width: 500px;
  }
}

@media (max-width: 768px) {
  .laptop-banner {
    flex-direction: column;
    max-width: 100%;
    height: auto;
    margin: 110px auto 0px auto; 
  }

  .laptop {
    top: -70px;
    width: 100%
  }

  .desktop {
    display: none;
  }

  .laptop-banner .laptop .mobile {
    display: block;
  }

  .laptop img {
    width: 100%;
  }

  .laptop-banner .info {
    left: 0px;
    padding: 33px;
    top: -100px;
  }

  .laptop-banner .info .flex-container {
    flex-direction: column;
  }

  .laptop-banner .info .flex-container span {
    width: 100%;
    justify-content: center;
  }
}

/* BLOCK INFO */
.block-info-flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin: auto;
  width: 1020px;
}

.block-info {
  width: 324px;
  height: 318px;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.block-info .bajada {
  padding: 25px;
}

.block-info .bajada h5 {
  font-family: "Public Sans";
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #009b41;
}

.block-info .bajada p {
  font-family: "Public Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 0px;
  color: #505c6f;
}

.block-info .bajada span {
  font-family: "Public Sans";
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  color: #009b41;
}

.block-info-text {
  width: 324px;
  height: 318px;
  padding-right: 110px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-info-text h3 {
  font-family: "Public Sans";
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #009b41;
  margin: 0px;
}

.block-info-text img {
  position: relative;
  top: -5px;
}

@media (max-width: 1000px) {
  .block-info-flex-container {
    width: 768px;
  }

  .block-info,
  .block-info-text {
    width: 30%;
    padding-right: 0px;
    height: auto;
  }

  .block-info .bajada p {
    height: 70px;
  }

  .block-info img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .block-info-flex-container {
    width: 100%;
    flex-direction: column;
    padding: 33px;
  }

  .block-info {
    width: 100%;
    height: auto;
  }

  .block-info img {
    width: 100%;
  }

  .block-info-text {
    height: auto;
    width: 100%;
  }
}

/* BRANDS */

.brands-title h3 {
  text-align: center;
  font-family: "Public Sans";
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #009b41;
  margin: 100px 0px 0px 0px;
}

.brands {
 display: flex;
 align-items: center;
}


.slick-slide div {
  outline: none !important;
}

.slick-prev:before,
.slick-next:before {
  color: #009B41 !important; 
}

.brands {
  width: 1000px;
  margin: auto;
}
.slider {
  margin: 70px auto 30px auto;
  width: 980px;
}

.brands img {
  width: 200px;
}

@media (max-width: 1000px) {
  .brands {
    width: 100%;
    margin: auto;
  }

  .slider {
    width: 90%;
  }

  .brands img {
    width: 145px;
  }
}

@media (max-width: 768px) {
  .brands-title h3{
    font-size: 20px;
    margin: 0px;
  }
  .brands {
    width: 100%;
  }

  .slider {
    width: 90%;
    margin: 20px auto;
  }

  .brands img {
    width: 100%;
  }
}

@media (max-width: 480px) {

  .brands {
    width: 95%;
  }

  .slider {
    width: 90%;
  }

  .brands img {
    width: 95%;
  }
}

/* TRACTOR BANNER */

.tractor-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 100px auto;
  background-color: #08582b;
  width: 1020px;
  height: 360px;
}

.tractor-banner .info {
  padding: 40px 50px;
  color: #fff;
}

.tractor-banner .info h3 {
  font-family: "Public Sans";
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  margin: 15px 0px;
}

.tractor-banner .info ul li {
  font-family: "Public Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-left: -20px;
}

.tractor-banner .tractor {
  position: relative;
  left: -20px;
}

@media (max-width: 1000px) {
  .tractor-banner {
    width: 768px;
    height: 400px;
  }

  .tractor-banner .tractor img {
    width: 400px;
  }
}

@media (max-width: 768px) {
  .tractor-banner {
    width: 100%;
    height: auto;
    flex-direction: column-reverse;
    margin: 0px;
  }

  .tractor-banner .info {
    padding: 40px 33px;
  }

  .tractor-banner .tractor {
    position: relative;
    left: 0px;
    width: 100%;
  }

  .tractor-banner .tractor img {
    width: 100%;
  }
}

/* PREGUNTAS FRECUENTES */

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



.preguntas-frecuentes {
  background: linear-gradient(
      to bottom,
      rgba(4, 35, 17, 0.6) 10.94%,
      rgba(4, 35, 17, 0) 100%
    ),
    url("../img/background-image.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 500px;
}

.preguntas-frecuentes h3 {
  text-align: center;
  font-family: "Public Sans";
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #ffffff;
  padding: 60px 20px 40px 20px;
  margin: 0px;
}

.preguntas-frecuentes .flex-container {
  margin: auto;
  width: 1100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.preguntas-frecuentes .item {
  width: 524px;
  padding: 17px;
  background: #f8f8f9;
  border-radius: 8px;
  margin: 10px;
}

.preguntas-frecuentes .item .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preguntas-frecuentes .item img {
  padding: 0px 10px;
}

.preguntas-frecuentes .item .title h3 {
  margin: 0px;
  padding: 0px;
  text-align: start;
  font-family: "Public Sans";
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #051124;
}

.preguntas-frecuentes .item .content p {
  margin: 10px auto;
  font-family: "Public Sans";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #505c6f;
}

.preguntas-frecuentes .item .content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.preguntas-frecuentes .content.active {
  height: auto;
  max-height: 999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 1);
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

@media (max-width: 2300px) {
  .preguntas-frecuentes {
    min-height: 700px;
  }

  .preguntas-frecuentes h3 {
    padding-top: 120px;;
  }
}


@media (max-width: 1100px) {
  .preguntas-frecuentes .flex-container {
    width: 100%;
  }
  .preguntas-frecuentes .item {
    width: 400px;
    margin: 10px;
  }
}

@media (max-width: 768px) {
  .preguntas-frecuentes {
    padding: 40px 33px;
    background: linear-gradient(
        to bottom,
        rgba(4, 35, 17, 0.9) 10.94%,
        rgba(4, 35, 17, 0) 100%
      ),
      url("../img/background-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .preguntas-frecuentes h3 {
    padding: 0px 0px 33px 0px;
  }

  .preguntas-frecuentes .flex-container {
    width: 100%;
  }

  .preguntas-frecuentes .item {
    margin: 10px 0px;
  }
}


/* PRODUCTOR */
/* PRODUCTOR HEADER */
.header-productor {
  background-image: url('../img/header-productor.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  height: 640px;
  position: relative;
}

.header-productor .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.header-productor .overlay .img-container {
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px
}


.header-productor .overlay .img-container img:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

@media (max-width: 750px) {
  .header-productor {
    background-image: url('../img/header-productor-mobile.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 640px;
    position: relative;
  }

@media (max-width: 500px) {
  .header-productor .overlay .img-container {
    flex-direction: column;
    margin-bottom: 50px;
  }

}
}

/* CONTAINER */ 
.cards-container {
  margin: 50px 100px;
}

@media (min-width: 1500px) {
  .cards-container {
    margin: 50px 300px
  }
}

@media (max-width: 800px) {
  .cards-container {
    margin: 20px 0px 0px 0px;
  }
}

/* OPERA */
.opera {
  background: #009B41;
  height: auto;
}

.opera .flex-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px 20px 20px 40px;
}

.opera .flex-container .col-1, .opera .flex-container .col-2 {
  width: 50%;
} 

.opera .flex-container .col-1 .titulo, .opera .flex-container .col-1 .item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.opera .flex-container .col-1 .titulo {
  margin: 40px 0px 80px 0px;
}

.opera .flex-container .col-1 .titulo img {
  margin-right: 20px;
}

.opera .flex-container .col-1 .titulo h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  color: #FFFFFF;
}

.opera .flex-container .col-1 .item {
  margin: 30px 25px 25px 0px;
}

.opera .flex-container .col-1 .item img {
  margin-right: 10px;
}

.opera .flex-container .col-1 .item p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}

.opera .flex-container .col-2 {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1200px) {
  .opera .flex-container {
    flex-direction: column-reverse;
    padding: 20px;
  }

  .opera .flex-container .col-1, .opera .flex-container .col-2 {
    width: 100%;
  }

  .opera .flex-container .col-2 {
    justify-content: center;
  }
  .opera .flex-container .col-2 img {
    width: 100%;
  }

}

/* CONTAINER-CARD */
.container-card {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin: 50px auto;
}

@media (max-width: 1000px) {
  .container-card {
    flex-direction: column;
    margin: 40px 0px;
    gap: 40px;
  }
}

@media (max-width: 800px) {
  .container-card {
    margin: 40px 20px;
  }
}

/* CARD */
.card {
  width: 33%;
  min-height: 280px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 20px;
}

.card img {
  width: 60px;
}

.card h6 {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #009B41;
  margin: 20px 0px;
}

.card p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #505C6F;
}

@media (max-width: 1200px) {
  .card {
    width: 100%;
    min-height: 220px;
  }
}

@media (max-width: 1000px) {
  .card {
    width: 100%;
    height: auto;
  }
}

/* FINANCIACION */
.financiacion {
  background: #009B41;
  padding: 70px 50px;
  margin: 50px auto;
}

.financiacion .flex-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.financiacion .flex-container .titulo {
  width: 25%;
}

.financiacion .flex-container .titulo h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  color: #FFFFFF;
}

.financiacion .flex-container .cards {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px
}

.financiacion .flex-container .cards .tarjeta {
  padding: 30px;
  background: #FFFFFF;
  border-left: 5px solid #19E572;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 100%;
}

.financiacion .flex-container .cards .tarjeta h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #009B41;
  margin-bottom: 20px;
}

.financiacion .flex-container .cards .tarjeta p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #828EA0;
}

.financiacion .flex-container .cards .tarjeta button {
  border: none;
  width: 152px;
  height: 38px;
  background: #009B41;
  border-radius: 40px;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
}

.financiacion .flex-container .cards .tarjeta button:hover {
  background-color: #03dd5e;
  color: #000;
  scale: 1.05;
}


@media (max-width: 1000px) {
  .financiacion {
    padding: 20px;
    margin-bottom: 0px;
  }

  .financiacion .flex-container {
    flex-direction: column;
  }

  .financiacion .flex-container .titulo {
    width: 100%;
  }
  
  .financiacion .flex-container .cards {
    width: 100%;
    gap: 20px
  }
}

/* BUSCADOR */
.buscador {
  background: #EEEEEF;
  padding: 50px;
  margin: 50px auto;
}

.buscador .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.buscador .flex-container h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  color: #009B41;
}

.buscador .flex-container p {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #828EA0;
  margin: 20px 0px;
}

.buscador .flex-container button {
  width: 200px;
  height: 40px;
  left: 526px;
  top: 185px;
  background: #009B41;
  border: none;
  border-radius: 40px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #EEEEEF;
  transition: all 0.3s ease-in-out;
}

.buscador .flex-container button img {
  padding-right: 10px;
}

.buscador .flex-container button:hover {
  background-color: #03dd5e;
  color: #000;
  scale: 1.05;
}

@media (max-width: 700px) {
  .buscador {
    padding: 50px 20px;
    margin: 0px;
  }

  .buscador .flex-container {
    flex-direction: column;
    gap: 50px;
  }
}

/* COMO FUNCIONA */
.como-funciona {
  text-align: center;
  margin: 50px 0px;
}

.como-funciona .logo {
  padding: 25px;
}

.como-funciona h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  text-align: center;
  color: #08582B;
}

.como-funciona .linea {
  padding: 20px;
}

.como-funciona .container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    margin-top: 30px;
}

.como-funciona .container .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1000px) {
  .como-funciona {
    margin: 20px;
  }
}

/* CONTACTO */
.contacto {
  background: #EEEEEF;
  padding: 115px 70px;
}

.contacto .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.contacto .flex-container .foto {
  width: 110%;
}

.contacto .flex-container .foto img {
  width: 100%;
}

.contacto .flex-container .formulario {
  width: 90%;
}

.contacto .flex-container .formulario img {
  padding-right: 10px;
}

.flex-container .formulario h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #009B41;
}

.flex-container .formulario p {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #505C6F;
}

@media (max-width: 1200px) {
  .contacto .flex-container {
    flex-direction: column;
  }
  .contacto .flex-container .foto {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .contacto {
    padding: 50px 20px;
  }
}


/* PROVEEDOR */
/* HEADER PROVEEDOR*/
.header-proveedor {
  background-image: url('../img/header-proveedor.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  height: 640px;
  position: relative;
}

.header-proveedor .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.header-proveedor .overlay .header-container {
  margin: 0px 100px;
}

.header-proveedor .overlay .header-container .img-container {
  margin: 40px auto 0px auto;
  display: flex;
  justify-content: flex-start;
  gap: 20px
}

.header-proveedor .overlay .img-container img:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.header-proveedor .overlay .header-container p {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  color: #FFFFFF;
  margin-bottom: 30px
}
@media (min-width: 1500px) {
  .header-proveedor .overlay{
    margin: 50px 200px
  }
}

@media (max-width: 800px) {
  .header-proveedor .overlay .header-container p {
    font-size: 32px;
    line-height: 38px;
  }
}

@media (max-width: 600px) {
  .header-proveedor .overlay .header-container {
    margin: 0px 50px;
  }

  .header-proveedor .overlay .header-container p {
    font-size: 30px;
    line-height: 36px;
  }
}

@media (max-width: 500px) {
  .header-proveedor .overlay .header-container {
    margin: 0px 30px;
  }

  .header-proveedor .overlay .header-container p {
    font-size: 28px;
    line-height: 34px;
  }

  .header-proveedor .overlay .header-container .img-container {
    margin: 40px auto 0px auto;
    flex-direction: column;
  }
}

/* @media (max-width: 750px) {
  .header-proveedor {
    background-image: url('../img/header-productor-mobile.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 640px;
    position: relative;
  }
} */

/* COBRAR */
.cobrar {
  margin: 50px 0px;
}

.cobrar .flex-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 80px;
  overflow: hidden;
}

.cobrar .flex-container .foto img {
  width: 100%;
  position: relative;
  left: -10px
}

.cobrar .flex-container .content {
  width: 50%;
}

.cobrar .flex-container .content p {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #828EA0;
  padding: 20px 20px 20px 0px;
}

@media (min-width: 1500px) {
  .cobrar {
    margin: 50px 300px
  }
  .cobrar .flex-container .foto img {
    left: 0px
  }
}

@media (max-width: 1100px) {
  .cobrar .flex-container {
    gap: 40px
  }

}

@media (max-width: 800px) {
  .cobrar .flex-container {
    flex-direction: column;
    align-items: center;
  }
  .cobrar .flex-container .foto {
    width: 100%;
  }
  
  .cobrar .flex-container .foto img {
    width: 100%;
  }
  .cobrar .flex-container .content {
    width: 85%;
    
  }
}

/* INSUMOS */
.insumos {
  background: #009B41;
  padding: 85px 70px;
  margin: 50px 100px;
}

.insumos .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.insumos .flex-container .foto {
  width: 50%
}

.insumos .flex-container .foto img {
  width: 100%
}

.insumos .flex-container .content {
  width: 50%
}

.insumos .flex-container .content h6 {
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: #FFFFFF;
  padding: 30px 0px;
}

.insumos .flex-container .content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
}
@media (min-width: 1500px) {
  .insumos {
    margin: 50px 300px
  }
}

@media (max-width: 1200px) {
  .insumos {
    padding: 50px;
    margin: 50px 0px;
  }
}

@media (max-width: 800px) {
  .insumos {
    padding: 30px;
  }
  .insumos .flex-container {
    flex-direction: column;
  }
  .insumos .flex-container .content {
    width: 100%;
  }
  .insumos .flex-container .foto {
    width: 100%;
  }
}

/* BENEFICIOS */
  .beneficios {
    margin: 50px 100px;
  }

  .beneficios .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px
  }

  .beneficios .flex-container .titulo {
    width: 35%
  }

  .beneficios .flex-container .titulo span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #828EA0;
  }

  .beneficios .flex-container .titulo h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 32px;
    color: #009B41;
    margin: 15px 0px;
  }

  .beneficios .flex-container .lista {
    width: 65%;
    background-image: url('../img/borde-punteado.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 545px;
    position: relative;
  }

  .beneficios .flex-container .lista .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .beneficios .flex-container .lista .overlay .flex-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    padding: 20px 0px;
    border-bottom: 0.3px solid rgb(80, 92, 111, 0.2);
    width: 80%;
    margin: auto;
  }

  .beneficios .flex-container .lista .overlay .flex-container:last-child {
    border-bottom: none;
  }

  .beneficios .flex-container .lista .overlay .flex-container span {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #505C6F;
  }

  .beneficios .flex-container .lista .overlay .flex-container img {
    padding-top: 3px;
  }

  @media (min-width: 1500px) {
    .beneficios {
      margin: 50px 300px
    }
  }

  @media (max-width: 1200px) {
    .beneficios {
      margin: 50px;
    }
  }

  @media (max-width: 800px) {
    .beneficios {
      margin: 50px 30px;
    }

    .beneficios .flex-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 40px;
    }

    .beneficios .flex-container .titulo {
      width: 100%; 
    }

    .beneficios .flex-container .lista {
      width: 100%;
    }

    .beneficios .flex-container .lista .overlay .flex-container {
      flex-direction: row;
      gap: 20px;
    }
  }

  @media (max-width: 500px) {
    .beneficios .flex-container .lista .overlay .flex-container span {
      font-size: 14px;
      line-height: 18px;
    }
  }

.instagram-widget {
  padding: 0px !important;
}

.eui-widget-title {
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 26px !important;
  padding-top: 50px !important;
}

.eapps-instagram-feed-posts-item-template-classic {
  border-radius: 5px !important;
  border: none !important;
}

.eapps-instagram-feed-posts-view {
  background-color: #2b9b40 !important;
  padding: 0px 15px 15px 15px !important;
}

.eapps-instagram-feed-posts-slider {
  width: 100% !important;
}


.eapps-instagram-feed-header {
  background: #2b9b40  !important;
  margin-bottom: 0px !important;
}



#eapps-instagram-feed-1 {
  background-color: #2b9b40  !important;
  padding-bottom: 20px !important;
}

.eapps-twitter-feed-posts-item-inner {
  border-radius: 5px !important;
  border: none !important;
  background-color: rgb(255, 255, 255);
}


.eapps-twitter-feed-header-statistics-item-data, .eapps-twitter-feed-header-user-info-name {
  color: #fff !important;
}

.eapps-twitter-feed-header-statistics-item-name,.eapps-twitter-feed-header-user-info-screen-name {
  color: #7fc698 !important;
  font-weight: 500;
}

  




